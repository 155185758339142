import React, { Component } from "react";
import {
  Alert,
  View,
  StyleSheet,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  ScrollView,
  TouchableHighlight,
  KeyboardAvoidingView,
  Platform
} from "react-native";
import { connect } from "react-redux";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { submitForm, getForm, beginSubmit, checkRecordStatus, showStatusError, checkLogStatus, showLogStatusError } from "../actions/menu";
import { sendSubmit, setBigFileIsLoading, loadingIssue, setIsSubmission, setSubmissionSelected } from "../actions/submissions";
import { setStatusChange, unsetStatusChange } from "../actions/records";
import { setIsLogGroup, getGroupDetail, setLogGroupsSubAction } from "../actions/loggroups";
import moment from "moment-timezone";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import FormComponent from "./component";
import uuid from "uuid";
import LogDialog from "./dialogs/log";
import Section from "./components/section";
import RecordActions from "./widgets/recordActions";
import colors from "../constants/colors";
import alerts from "./components/alert"
import StorageService from "../services/storage";
import PaymentInfo from './dialogs/paymentInfo';
import Swal from "sweetalert2";
import "./styles.css";
import { viewContainer } from "./functions";
import { createNewTask } from "../actions/tasks";
import { getSalesGuide } from "../actions/login";
import StepIndicator from 'react-native-step-indicator';

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submission: {},
      lastStatus: false,
      checkboxkey: 0, // increment by 1 each time yesnoall is clicked
      components: [],
      choicelist: {},
      uuid: "",
      derived: false,
      autosave: false,
      tmpIssues: [],
      showPaymentInfo: false,
      callOnSave: false,
      lotConsecutive: 1,
      formLoaded: false,
      width: window.innerWidth,
      sectionIndex: 0,
      errorMessage: "",
      active: 0
    };
  }

  handleResize = () => {
    this.setState({ width: window.innerWidth });
  }

  componentDidMount() {
    if (!this.props.form_id) {
      this.setState({ errorMessage: "Sorry, this link needs a form id, please add one" });
    } else {
      this.props.getSalesGuide(this.props.form_id, (res) => {
        if (res.message) {
          Swal.fire({
            title: res.message,
            confirmButtonText: "Ok",
            confirmButtonColor: "#F4981E",
            backdrop: false
          })
          this.setState({ errorMessage: res.message });
        } else {
          setTimeout(() => {
            this.getForm();
          }, 100)
        }
      });
    }

    window.addEventListener('resize', this.handleResize);
  }

  getForm() {
    this.props.getForm(this.props.form_id, () => {
      setTimeout(() => {
        this.createTask();
        this.setState({ formLoaded: true });
      }, 100);
    });
  }

  createTask() {
    const { formName, formId } = this.props;
    let start = moment.tz(new Date(), "UTC").format("YYYY-MM-DD HH:mm");

    let object = {
      name: formName,
      description: formName,
      forms_id: formId,
      start_date: start,
      expiration_date: moment(start).add(1, "day").format("YYYY-MM-DD HH:mm"),
      repeat_frequency: "",
      status: "archive"
    };
    this.props.createNewTask(object);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  checkLotSubmissions() {
    const { submissionSelected } = this.props;
    console.log(submissionSelected)
    let num_array = [];
    if (submissionSelected) {
      let sub_id = submissionSelected._id.slice(-8);
      for (let component of submissionSelected.components) {
        let obj = submissionSelected.submission[component.id];
        if (obj) {
          obj = obj.toString();
          if (component.type === "lotcode" || component.type === "customlotcode") {
            if (obj.includes(sub_id)) {
              obj = parseInt(obj.replace(sub_id, ""));
              num_array.push(obj);
            }
          }
          if (component.type === "inventory") {
            if (obj.lotcode && obj.lotcode.includes(sub_id)) {
              obj = parseInt(obj.replace(sub_id, ""));
              num_array.push(obj);
            }
          }
        }
      }
      var max = Math.max(...num_array);
      var min = Math.min(...num_array);
      console.log(max);
      console.log(min);
      console.log(num_array);
      if (max && max > 0) {
        this.setState({ lotConsecutive: max + 1 })
      }
    }
  }

  setCompleteStatus() {
    const { submission } = this.state;
    if (submission.status) {
      this.setState({ lastStatus: submission.status })
    }
    submission.status = "complete";
    this.setState({ submission: submission }, () => {
      this.submitForm("complete");
    });
  }

  saveForm = () => {
    this.setState(
      {
        autosave: true,
      },
      () => {
        this.props.autoSave(
          this.state.components,
          this.state.choicelist,
          this.state.submission,
          this.props.newReport ? uuid.v1() : this.state.uuid,
          this.props.task_id,
          this.state.autosave
        );
      }
    );
  };

  getComponentById(id) {
    const { components } = this.state;
    var r = null;
    for (var i = 0; i < components.length; i++) {
      if (components[i]["id"] == id) return components[i];
    }
  }

  onComponentChange(id, value, subtype = false) {
    const { submission, components, lotConsecutive } = this.state;
    // let newSubmission = Object.assign({}, submission);
    let newSubmission = JSON.parse(JSON.stringify(submission));

    let checkboxkey = this.state.checkboxkey;
    var c = this.getComponentById(id);
    if (typeof c != "undefined") {
      if (subtype) {
        if (typeof newSubmission[c.id] != "undefined") {
          newSubmission[c.id][subtype] = value;

        } else {
          newSubmission[c.id] = {};
          newSubmission[c.id][subtype] = value;
        }
        if (subtype === "lotcode") {
          this.setState({ lotConsecutive: lotConsecutive + 1 })
        }
      }
      else
        newSubmission[c.id] = value;
      // YesNoAll logic
      if (c.type === "yesnoall") {
        var section = components[id].section_id;
        components.forEach((c) => {
          if (c.type === "yesno" && c.section_id === section) {
            newSubmission[c.id] = value;
          }
        });
        // force yesno components to remount
        checkboxkey = checkboxkey + 1;
      }
      if (c.type === "lotcode" || c.type === "customlotcode") {
        this.setState({ lotConsecutive: lotConsecutive + 1 })
      }
    } else {
      if (subtype) {
        newSubmission[id] = {};
        newSubmission[id][subtype] = value;
      }
      else
        newSubmission[id] = value;
    }

    this.setState({ submission: newSubmission, checkboxkey: checkboxkey });
  }

  UNSAFE_componentWillReceiveProps(newProps) {

    console.log('UNSAFE_componentWillReceiveProps')
    console.log(this.props)
    console.log(newProps)
    const { companyTimezone } = this.props;
    var submission = {};
    var components = [];
    var choicelist = {};
    var uuid = "";

    var issues = newProps.submissionSelected?.issues
    if (issues) {
      issues = Object.values(issues)
      if (issues.length >= 0) {
        this.setState({ tmpIssues: issues }, this.forceUpdate.bind(this));
      }
    }
    // Initialize right after the form is loaded from network/memory
    if (!newProps.isLoaded || !this.props.isLoading) {
      return;
    }

    // Load old submission
    if (!this.props.newReport && !this.props.isSubmission) {
      uuid = this.props.previousSubmissions[this.props.task_id]["uuid"];
      submission = this.props.previousSubmissions[this.props.task_id][
        "submission"
      ];
      components = this.props.previousSubmissions[this.props.task_id][
        "components"
      ];
      choicelist = this.props.previousSubmissions[this.props.task_id][
        "choicelist"
      ];
    }

    if (!this.props.newReport && this.props.isSubmission) {
      uuid = this.props.submissionSelected.uuid;
      submission = this.props.submissionSelected.submission;
      const tmpComponents = this.props.submissionSelected.components;
      choicelist = this.props.submissionSelected.choicelist;
      let indextmpComponents = 0
      tmpComponents.forEach((comp, indx) => {
        let nComponent = { ...comp }
        if (comp.type === "section") {

          newProps.components.forEach(nComp => {
            if (nComp.label === comp.label) {
              if (nComp.attachmentId && typeof nComp.attachmentId !== 'undefined' && typeof nComp.attachmentId !== '' && typeof nComp.attachmentId !== null) {
                nComponent.attachment = nComp.attachment;
                nComponent.attachment_type = nComp.attachment_type
                nComponent.attachmentId = nComp.attachmentId

              }
            }

          });
        }
        components.push(nComponent)
      })
    }

    if ("components" in newProps && "choicelist" in newProps) {
      const date = moment().format("YYYY-MM-DD HH:mm");
      const initialDateTime = moment.tz(date, companyTimezone).tz('UTC').format("YYYY-MM-DD HH:mm");
      // New report
      if (this.props.newReport) {
        components = newProps.components;
        choicelist = newProps.choicelist;
        components.forEach((c) => {
          if (c.type === "datetime") submission[c.id] = initialDateTime;
          else if (
            c.type === "inputlabel" ||
            c.type === "input" ||
            c.type === "textarea" ||
            c.type === "lotecode" ||
            c.type === "customlotcode"
          )
            submission[c.id] = "";
          else if (c.type === "dropdown")
            submission[c.id] = choicelist[c.object_type_id][0]["id"];
          else if (c.type === "picture" || c.type === "signature")
            submission[c.id] = 0;
          else if (c.type == "yesno" || c.type == "yesnoall")
            submission[c.id] = 0;
          else if (c.type == "document")
            submission[c.id] = "";
        });
      }
    }

    this.setState({
      submission,
      components,
      choicelist,
      uuid,

    });
  }

  // Do not render when this.state.submissions is modified
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.isLoading !== nextProps.isLoading ||
      this.props.isErrorSave !== nextProps.isErrorSave ||
      this.props.isErrorRetrieve !== nextProps.isErrorRetrieve ||
      this.props.isLoaded !== nextProps.isLoaded ||
      this.props.isSaving !== nextProps.isSaving ||
      this.state.lotConsecutive !== nextState.lotConsecutive ||
      this.state.checkboxkey !== nextState.checkboxkey ||
      this.state.sectionIndex !== nextState.sectionIndex ||
      this.state.errorMessage !== nextState.errorMessage ||
      this.state.active !== nextState.active || 
      this.state.formLoaded !== nextState.formLoaded
    );
  }

  submitFormSave(status = "in_progress") {
    this.props.startSubmit(
      this.state.components,
      this.state.choicelist,
      this.state.submission,
      this.props.newReport ? uuid.v1() : this.state.uuid,
      this.props.taskSelected._id,
      this.props.isLogGroup ? this.props.logGroupSelected.id : '',
      status
    );
    // this.props.logGroupSelected.id && this.props.isLogGroup ? this.navigateToGroup() : this.props.navigation.navigate('MainView');

  }

  submitForm = async (status) => {
    const { submission, lastStatus } = this.state;
    let cont = 0;
    let labels = [];
    const result = this.state.components.filter(f => f.mandatory === true);

    for (let i = 0; i < result.length; i++) {
      let index = Object.keys(this.state.submission).indexOf(result[i].id);
      let valueMandatory = Object.values(this.state.submission)[index];
      console.log("--------------------------------")
      console.log(result[i].label)
      console.log(valueMandatory)
      console.log("--------------------------------")

      if (valueMandatory != undefined) {
        if (valueMandatory.length > 0 || valueMandatory != 0) {

          if (result[i].type === 'inventory') {
            if (valueMandatory.lotcode && valueMandatory.productname && valueMandatory.quantity) {
              if (valueMandatory.lotcode.length > 0 && valueMandatory.productname.length > 0 && valueMandatory.quantity.length > 0) {
                console.log(valueMandatory)
                console.log("success")
                cont++;
              } else {
                labels.push(result[i].label);
                console.log("error")
              }
            } else {
              labels.push(result[i].label);
              console.log("error")
            }

          } else {
            console.log("success")
            cont++;
          }
        } else {
          labels.push(result[i].label);
          console.log("error")
        }
      } else {
        labels.push(result[i].label);
      }
    }

    if (cont === result.length) {
      await this.props.unsetStatusChange();
      console.log("SAVED!!")
      this.props.startSubmit(
        this.state.components,
        this.state.choicelist,
        this.state.submission,
        this.props.newReport ? uuid.v1() : this.state.uuid,
        this.props.taskSelected._id,
        this.props.isLogGroup ? this.props.logGroupSelected.id : '',
        status
      );
      // this.props.logGroupSelected.id && this.props.isLogGroup ? this.navigateToGroup() : this.props.navigation.navigate('MainView');

    } else {
      await this.props.setStatusChange();
      submission.status = lastStatus;
      console.log("is in validation")
      this.setState({ submission: submission })

      Swal.fire({
        title: "Please check your form",
        text: "Complete the required fields before submitting the report " + '(' + labels.toString().replaceAll(",", ", ") + ')',
        confirmButtonText: "Ok",
        confirmButtonColor: "#F4981E",
        backdrop: false
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("--------------------------------")
        }
      })
    }
  }


  navigateToGroup() {
    this.props.setLogGroupsSubAction()
  }

  renderIssue(issues, component) {
    let issueComment = "";
    let found = false;
    let issueRender = []
    if (Array.isArray(issues))
      issues?.map((issue) => {
        if (issue.id == component.id) {
          issueComment = issue.comment;
          found = true;
          issueRender = issue;
        }
      });

    return found ? issueRender : null;

  }

  printAllIssues(issues, component) {
    const { companyTimezone } = this.props;
    const locIssues = issues.filter(issue => issue?.id == component?.id);
    return (
      <View style={{ flex: 1 }}>

        {locIssues.map(issue => {
          // var cDate = new Date(issue.createdDate)
          // var sDate = new Date(issue.solvedDate)
          return <React.Fragment>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <TouchableOpacity style={[styles.issueBox, { flex: 7 }]}>
                {issue.solved ?
                  <Text style={[styles.issueText, { fontStyle: 'italic', textDecorationLine: 'line-through' }]}>{issue?.comment}</Text> :
                  <Text style={styles.issueText}>{issue?.comment}</Text>}
              </TouchableOpacity>
              {!issue.solved &&
                <TouchableOpacity style={[styles.issueBox, { flex: 1, marginLeft: 5, backgroundColor: '#fff0a3' }]}
                  onPress={() => {
                    const { submissionSelected } = this.props;
                    const commentIndx = issues.findIndex(elem => elem?.comment == issue?.comment)
                    const newIssues = [...issues];
                    newIssues[commentIndx] = {
                      ...issue,
                      solved: true
                    }
                    this.props.loadingIssue(component.id);
                    this.props.sendSubmit(submissionSelected?._id, newIssues, newIssues[commentIndx].comment, () => this.props.loadingIssue(null));
                  }}
                >
                  <Text style={styles.issueText}>Solve</Text>
                </TouchableOpacity>
              }
            </View>
            {
              issue.createdBy && issue.createdDate ?
                <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Created by {issue.createdBy} on {moment(issue.createdDate).tz(companyTimezone).format('YYYY-MM-DD')}</Text>
                // <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Created by {issue.createdBy} on {this.convertUTCDateToLocalDate(cDate).toLocaleString('en-US')}</Text>

                : null
            }
            {
              issue.solvedBy && issue.solvedDate ?
                <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Solved by {issue.solvedBy} on {moment(issue.solvedDate).tz(companyTimezone).format('YYYY-MM-DD')}</Text>
                // <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Solved by {issue.solvedBy} on {this.convertUTCDateToLocalDate(sDate).toLocaleString('en-US')}</Text>

                : null
            }
            <View>

            </View>
          </React.Fragment>
        }
        )
        }

      </View>
    )
  }

  showSave() {

    const { submissionSelected, formId, taskSelected, isLogGroup } = this.props;
    const { formLoaded } = this.state;
    const status = submissionSelected ? submissionSelected.status : "new";

    if (status != 'archived' && status != 'approved' && status != 'complete')
      return (
        <View style={styles.submitContainer}>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              if (this.props.payment_completed) {
                //Validate Status
                var id = taskSelected ? taskSelected?._id : null;
                if (status == "new" && isLogGroup) {
                  this.props.checkLogStatus(id, (statusBD) => {
                    if (!statusBD || statusBD == "pending" || statusBD == "edit_required") {
                      if (formLoaded) this.submitFormSave('in_progress');
                    } else {
                      this.props.showLogStatusError(statusBD);
                    }
                  })

                } else {
                  if (!id) {
                    id = this.props.newReport ? uuid.v1() : this.state.uuid;
                  }
                  this.props.checkRecordStatus(id, (statusBD) => {
                    if (!statusBD || statusBD == "in_progress" || statusBD == "edit_required") {
                      if (formLoaded) this.submitFormSave('in_progress');
                    } else {
                      this.props.showStatusError(statusBD);
                    }
                  })
                }

              } else {
                this.setState({ showPaymentInfo: true, callOnSave: true }, () => { this.forceUpdate() });
              }
            }}
          >
            <Text style={styles.buttonText}>
              Save
            </Text>
          </TouchableOpacity>
        </View>
      );
    else if (status != 'complete')
      return (
        <TouchableOpacity style={styles.readOnlyContainer}>
          <Text style={{ alignSelf: "center" }}>This record is read only</Text>
        </TouchableOpacity>
      );


  }

  saveIssue(issue, issues, submissionId,) {
    const newIssues = Array.isArray(issues) ? [...issues, issue] : [issue];
    this.props.sendSubmit(submissionId, newIssues, issue.comment);
  }

  convertUTCDateToLocalDate(date) {

    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString('en-US');
  }
  nextSection() {
    const { sectionIndex, components } = this.state;
    const compCount = components.filter(section => section.type == 'section');
    if (sectionIndex != (compCount.length - 1)) {
      this.setState({ sectionIndex: sectionIndex + 1 });
    }
  }
  previousSection() {
    const { sectionIndex } = this.state;
    if (sectionIndex != 0) {
      this.setState({ sectionIndex: sectionIndex - 1 });
    }
  }
  onPressSection(val) {  
      this.setState({ sectionIndex: val });
  }
  getLabels() {
    const { components } = this.state;
    var array = [];
    components.filter(section => section.type == 'section').map((section, id) => {
      array.push(section.label);
    });

    return array;
  }

  getLabelsLength() {
    const { components } = this.state;
    var array = [];
    components.filter(section => section.type == 'section').map((section, id) => {
      array.push('');
    });

    return array.length;
  }

  sectionTitle() {
    const { components, sectionIndex } = this.state;
    const compCount = components.filter(section => section.type == 'section');
    if (compCount.length > 0) {
      return compCount[sectionIndex].label;
    }
  }

  render() {
    const { submission, choicelist, checkboxkey, showPaymentInfo, callOnSave, components, lotConsecutive, formLoaded, sectionIndex, errorMessage, active } = this.state;
    const { submissionSelected, formSubmission, isLoadingIssue, companyTimezone, formName, company_logo, promptMessages } = this.props;
    let issues = submissionSelected ? submissionSelected.issues : null;
    console.log(promptMessages);
    if (this.state.tmpIssues.length > 0)
      issues = this.state.tmpIssues;
    const submissionId = submissionSelected ? submissionSelected._id : null;
    console.log(errorMessage)
    if (errorMessage != "") {
      return <View style={{ flexDirection: "row", }}>
        <Text style={styles.labelFirst2}>{errorMessage}</Text>
      </View>
    }
    return (
      <View style={{ flexDirection: "row", }}>
        <PaymentInfo visible={showPaymentInfo} onSave={this.submitFormSave.bind(this)} callOnSave={callOnSave} />
        <View style={{ flex: 2, }} />
        <View className={"viewContainer"} style={viewContainer(this.state.width, styles)} >
          {
            !this.props.isLoading &&
            <View style={{
              display: 'flex', flexDirection: 'row', marginTop: 20
            }}>
              <Text style={styles.labelFirst3}>{formName}</Text>
            </View>
          }
          {
            !this.props.isLoading &&
            <StepIndicator
              customStyles={customStyles}
              currentPosition={sectionIndex}
              labels={this.getLabels(components)}
              stepCount={this.getLabelsLength(components)}
              onPress={(event) => this.onPressSection(event)}
            />
          }
          {/* {
            !this.props.isLoading &&
            <View style={{
              display: 'flex', flexDirection: 'row', marginTop: 20
            }}>
              <View style={{ width: '100%', textAlign: 'center' }}>
                <Text style={styles.labelFirst}>{this.sectionTitle()}</Text>
              </View>
            </View>
          } */}
          {
            !this.props.isLoading &&
            <View style={{
              display: 'flex', flexDirection: 'row', marginTop: 20
            }}>
              <View style={{ flex: 1 }} >
                {
                  sectionIndex != 0 &&
                  <TouchableOpacity onPress={() => this.previousSection()}>
                    <Icon name="arrow-left" style={styles.iconStyle} />
                  </TouchableOpacity>
                }

              </View>
              <View style={{ flex: 10, textAlign: 'center' }}>
                <Text style={styles.labelFirst}>{this.sectionTitle()}</Text>
              </View>
              <View style={{ flex: 1 }}>
                {
                  sectionIndex != (components.filter(section => section.type == 'section').length - 1) &&
                  <TouchableOpacity onPress={() => this.nextSection()}>
                    <Icon name="arrow-right" style={styles.iconStyle} />
                  </TouchableOpacity>
                }
              </View>
            </View>
          }
          {
            this.props.isLoaded || (this.props.isSaving && this.state.autosave) ? (

              <ScrollView
                contentContainerStyle={styles.innerView}
              >


                {components.filter(section => section.type == 'section').map((section, id) => {
                  if (sectionIndex == id) {
                    return <Section
                      showSeparator={id === 0}
                      isLast={id == components.filter(section => section.type == 'section').length - 1}
                      label={section.label}
                      onChange={(id, value, subtype) => this.onComponentChange(id, value, subtype)}
                      id={section.id}
                      media={section.attachment}
                      formSubmission={formSubmission}
                      attachment_type={section.attachment_type}
                      mediaId={typeof section.attachmentId !== 'undefined' ? section.attachmentId : null}
                      formId={this.props.formId}
                      setBigFileIsLoading={this.props.setBigFileIsLoading}

                    >
                      {components.filter(component => component.section_id == section.section_id && component.type != 'section').map((component) => {
                        let new_label;
                        console.log(component.placeholder);
                        switch (component.type) {
                          case "customlotcode":
                            new_label = `${component.label} - ${component.lotcodetypename}`;
                            break;
                          case "inventory":
                            new_label = `${component.label} - ${component.inventorytypename} Code`;
                            if (typeof submission[component.id] == "undefined")
                              submission[component.id] = { lotcode: "", productname: "", quantity: "" };
                            break;
                          default:
                            new_label = `${component.label}`;
                        }

                        return <>
                          {
                            isLoadingIssue != null && component.id == isLoadingIssue ?
                              <ActivityIndicator size={150} color="rgb(0, 47, 108)"></ActivityIndicator>
                              :
                              <View>

                                <FormComponent
                                  id={component.id}
                                  type={component.type}
                                  key={component.id}
                                  label={component.mandatory && component.type != 'datetime' && component.type != 'datetime' ? new_label + " *" : new_label}
                                  placeholder={component.placeholder ? component.placeholder : ""}
                                  componentLink={component.componentLink ? component.componentLink : ""}
                                  enabledLink={component.enabledLink ? component.enabledLink : ""}
                                  initial={submission[component.id]}
                                  onChange={(id, value, subtype) => {
                                    this.onComponentChange(id, value, subtype)

                                  }}
                                  options={choicelist[component.object_type_id]}
                                  checkboxkey={checkboxkey}
                                  status={submissionSelected ? submissionSelected.status : false}
                                  submissionSelected={submissionSelected ? submissionSelected : null}
                                  showLotGenerator={component.showLotGenerator ? component.showLotGenerator : null}
                                  lotConsecutive={lotConsecutive}
                                  customlot={false}
                                  isIssuable={submissionId != null}
                                  onSubmitIssue={(issue) => {
                                    this.props.loadingIssue(component.id);
                                    this.saveIssue(issue, issues, submissionId)
                                    setTimeout(() => {
                                      this.props.loadingIssue(null);
                                    }, 1000)
                                  }}
                                  companyTimezone={companyTimezone}
                                  promptMessages={promptMessages}
                                />
                                {issues && this.renderIssue(issues, component) != null ? <View style={{
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: 10,
                                  marginBottom: 10,

                                }}>
                                  {this.printAllIssues(issues, component)}
                                </View> : null}

                              </View>
                          }

                        </>



                      })}
                    </Section>
                  } else {
                    return null;
                  }
                })}
                {
                  !this.props.isLoading &&
                  <View style={{
                    display: 'flex', flexDirection: 'row', marginTop: 20, marginBottom: 20
                  }}>
                    <View style={{ flex: 1 }} >
                      {
                        sectionIndex != 0 &&
                        <TouchableOpacity onPress={() => this.previousSection()}>
                          <Icon name="arrow-left" style={styles.iconStyle} />
                        </TouchableOpacity>
                      }

                    </View>
                    <View style={{ flex: 10, textAlign: 'center' }}></View>
                    <View style={{ flex: 1 }}>
                      {
                        sectionIndex != (components.filter(section => section.type == 'section').length - 1) &&
                        <TouchableOpacity onPress={() => this.nextSection()}>
                          <Icon name="arrow-right" style={styles.iconStyle} />
                        </TouchableOpacity>
                      }
                    </View>
                  </View>
                }
                {
                  !formSubmission && (sectionIndex == components.filter(section => section.type == 'section').length - 1) ?

                    <RecordActions setCompleteStatus={() => { this.setCompleteStatus() }}
                      formLoaded={formLoaded}
                      submitForm={(status) => status ? this.submitForm(status) : this.submitForm()}
                      submitFormSave={(status) => status ? this.submitFormSave(status) : this.submitFormSave()} record={this.props.submissionSelected} navigateTo={(page) => console.log('navigateTo')} />
                    : null
                }
                {/* {
                  !formSubmission ? 

                    <RecordActions setCompleteStatus={() => { this.setCompleteStatus() }}
                      formLoaded={formLoaded}
                      submitForm={(status) => status ? this.submitForm(status) : this.submitForm()}
                      submitFormSave={(status) => status ? this.submitFormSave(status) : this.submitFormSave()} record={this.props.submissionSelected} navigateTo={(page) => this.props.navigation.navigate(page)} />
                    : null
                }
                {
                  !formSubmission ?
                    this.showSave()
                    :null
                } */}
                {
                  formSubmission ?
                    <TouchableOpacity style={styles.readOnlyContainer}>
                      <Text style={{ alignSelf: "center" }}>This record is read only, the form was deleted from the admin and this record can not be modified.</Text>
                    </TouchableOpacity> : null
                }



              </ScrollView>
            ) : this.props.isLoading ? (
              <View style={styles.fixedContainer}>
                <ActivityIndicator></ActivityIndicator>
                <Text style={styles.loadingText}>Loading...</Text>
              </View>
            ) : this.props.isSaving && !this.state.autosave ? (
              <View style={styles.fixedContainer}>
                <ActivityIndicator></ActivityIndicator>
                <Text style={styles.loadingText}>Saving...</Text>
              </View>
            ) : this.props.isLoadingBigFile ?
              <View style={styles.fixedContainer}>
                <ActivityIndicator></ActivityIndicator>
                <Text style={styles.loadingText}>Uploading...</Text>
              </View>
              : (
                <View style={styles.fixedContainer}>
                  <View style={styles.fixedContainerHT}>
                    <MaterialCommunityIcons
                      name="alert-circle"
                      size={32}
                      color="#d00"
                      style={styles.icon}
                    />
                    <Text style={styles.errorText}>{this.props.error}</Text>
                  </View>
                  <View style={styles.fixedContainerHB}>
                    <TouchableOpacity
                      onPress={() => {
                        this.props.retry(
                          this.props.isErrorSave,
                          this.props.components,
                          this.props.choicelist,
                          this.state.submission,
                          this.props.newReport ? uuid.v1() : this.state.uuid,
                          this.props.task_id
                        );
                      }}
                    >
                      <Text>Retry</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              )}

          <LogDialog />
        </View>
        <View style={{ flex: 2 }} />
      </View>
    );


  }
}

export default connect(
  (state) => {
    return {
      isLoading: state.forms.getIn(["form", "status"]) === "loading",
      isLoadingBigFile: state.forms.getIn(["form", "status"]) === "loading_big_file",
      isErrorSave: state.forms.getIn(["form", "status"]) === "errorSaving",
      isErrorRetrieve:
        state.forms.getIn(["form", "status"]) === "errorRetrieving",
      formId: state.forms.getIn(['form', 'id']),
      isLoaded: state.forms.getIn(["form", "status"]) === "loaded",
      isSaving: state.forms.getIn(["form", "status"]) === "saving",
      error: state.forms.getIn(["form", "errorMessage"]),
      components: state.forms.getIn(["form", "components"]),
      promptMessages: state.forms.getIn(["form", "promptMessages"]),
      formName: state.forms.getIn(["form", "name"]),
      choicelist: state.forms.getIn(["form", "choicelist"]),
      formSubmission: state.forms.getIn(["form", "formSubmission"]),
      previousSubmissions: state.submissions.get("previousSubmissions"),
      /*syncState: state.sync.get("state"),*/
      isLogGroup: state.loggroups.get("isLogGroup"),
      logGroupSelected: state.loggroups.get("logGroupSelected"),
      submissionSelected: state.submissions.get("submissionSelected"),
      isSubmission: state.submissions.get("isSubmission"),
      isLoadingIssue: state.submissions.get("loadingIssue"),
      payment_completed: state.user.get("payment_completed"),
      companyTimezone: state.user.get("companyTimezone"),
      taskSelected: state.tasks.get("taskSelected"),
      company_logo: state.user.get("company_logo")

    };
  },
  (dispatch, props) => {

    return {
      createNewTask: (task) => {
        return new Promise((resolve, reject) => {
          dispatch(setIsSubmission(false));
          dispatch(setSubmissionSelected(null));
          dispatch(createNewTask(task));
          resolve();
        });
      },
      getSalesGuide: (id, callback) => {
        dispatch(getSalesGuide(id, callback));
      },
      sendSubmit: (submissionId, submissions, comment, callback) => dispatch(sendSubmit(submissionId, submissions, comment, callback)),
      checkRecordStatus: (uuid, callback) => dispatch(checkRecordStatus(uuid, callback)),
      showStatusError: (status) => dispatch(showStatusError(status)),
      checkLogStatus: (task_id, callback) => dispatch(checkLogStatus(task_id, callback)),
      showLogStatusError: (status) => dispatch(showLogStatusError(status)),
      loadingIssue: (value) => dispatch(loadingIssue(value)),
      setBigFileIsLoading: (payload) => dispatch(setBigFileIsLoading(payload)),
      getForm: (idSubmission, callback) => {
        dispatch(getForm(props.formId, idSubmission, callback));
      },
      setStatusChange: () => { dispatch(setStatusChange()) },
      unsetStatusChange: () => { dispatch(unsetStatusChange()) },
      setLogGroupsSubAction: () => {
        dispatch(setLogGroupsSubAction());
      },
      retry: (
        isErrorSave,
        components,
        choicelist,
        submission,
        uuid,
        task_id,
        status
      ) => {
        if (isErrorSave) {
          dispatch(
            beginSubmit(
              props.formId,
              props.title,
              components,
              choicelist,
              submission,
              uuid,
              task_id,
              false,
              status
            )
          );
        } else {
          dispatch(getForm(props.formId, props.submissionSelected?._id));
        }
      },
      startSubmit: (
        components,
        choicelist,
        submission,
        uuid,
        task_id,
        logroup,
        status
      ) => {
        dispatch(
          beginSubmit(
            props.formId,
            props.title,
            components,
            choicelist,
            submission,
            uuid,
            task_id,
            false,
            logroup,
            status
          )
        );
      },
      autoSave: (
        components,
        choicelist,
        submission,
        uuid,
        task_id,
        autosave
      ) => {
        dispatch(
          beginSubmit(
            props.formId,
            props.title,
            components,
            choicelist,
            submission,
            uuid,
            task_id,
            autosave
          )
        );
      },
    };
  }
)(Form);

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: "#B3B7BC",
    flex: 1,
    flexDirection: "column",
  },
  issueContainer: {
    flex: 1,
    flexDirection: "column",
    borderRadius: 10,
    backgroundColor: 'pink',
  },
  readOnlyContainer: {
    flexGrow: 1,
    backgroundColor: "lightblue",
    padding: 10,
    marginHorizontal: 10,
    borderRadius: 10,
    marginBottom: 10,
  },
  container: {
    flex: 1,
    backgroundColor: "#B3B7BC",
    flexDirection: "column",
    justifyContent: "center",
  },
  keyboardAvoid: {
    flex: 1,
  },
  loadingText: {
    color: "#444",
    marginTop: 5,
  },
  fixedContainer: {
    flex: 1,
    flexDirection: "column",
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  fixedContainerHT: {
    flexDirection: "row",
    alignItems: "center",
  },
  fixedContainerHB: {
    marginTop: 20,
  },
  errorText: {
    color: "#444",
    marginLeft: 5,
  },
  innerView: {
    flexDirection: "column",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#fff",
    marginBottom: 20,
    shadowOffset: {
      width: 7,
      height: 5,
    },
    shadowRadius: 5,
    shadowOpacity: 0.1,
  },
  menuContainer: {
    flex: 1,
    flexDirection: "column",
    paddingHorizontal: 10,
    paddingTop: 20,
    paddingBottom: 30,
    marginBottom: 20,
  },
  submitContainer: {
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: 20,
    paddingHorizontal: 10,
  },
  button: {
    alignItems: "center",
    backgroundColor: colors.gray_darken_2,
    borderRadius: 5,
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: 10,
    height: 60,
  },
  buttonText: {
    color: colors.primary,
    fontSize: 18,
    fontFamily: "Roboto",
  },
  iconInProgress: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightgray',
  },
  iconEditRequired: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightgray',
  },
  iconReadyForReview: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightgray',
  },
  iconReadyForApproval: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightblue',
  },
  iconApproved: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightgreen',
  },
  iconRejected: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'pink',
  },
  issueBox: {
    alignItems: "center",
    backgroundColor: 'pink',
    borderRadius: 5,
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: 10,
    marginBottom: 10,
    height: 60,
  },
  issueText: {
    fontSize: 18,
    fontFamily: "Roboto",
    color: "#444",
  },
  readOnlyTxt: {
    fontSize: 22,
    borderRadius: 4,
    backgroundColor: 'pink',
    margin: 10,
    padding: 10,
    fontFamily: "Roboto",
  },
  viewContainerMax: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    minWidth: 600,
    paddingTop: 20,
    paddingLeft: 0,
    paddingRight: 0,
  },
  viewContainerMax1: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    minWidth: 450,
    paddingTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  viewContainerMax2: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    minWidth: 350,
    paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  viewContainerMax3: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    minWidth: 300,
    paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  labelFirst: {
    fontSize: 20,
    color: colors.primary,
    fontWeight: 'bold',
    wordBreak: 'break-word',
    wordWrap: 'break-word'
  },
  labelFirst2: {
    fontSize: 20,
    color: colors.primary,
    fontWeight: 'bold',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    textAlign: 'center',
    width: '100%',
    marginTop: 30,
  },
  labelFirst3: {
    textAlign: 'center',
    width: '100%',
    marginBottom: 30,
    fontSize: 22,
    color: colors.primary,
    fontWeight: 'bold',
    wordBreak: 'break-word',
    wordWrap: 'break-word'
  },
  iconStyle: {
    color: colors.primary,
    fontSize: 30,
    cursor: 'pointer'
  }

});

const customStyles = {
  stepIndicatorSize: 25,
  currentStepIndicatorSize: 30,
  separatorStrokeWidth: 2,
  currentStepStrokeWidth: 3,
  stepStrokeCurrentColor: '#002f6c',
  stepStrokeWidth: 3,
  stepStrokeFinishedColor: '#002f6c',
  stepStrokeUnFinishedColor: '#aaaaaa',
  separatorFinishedColor: '#002f6c',
  separatorUnFinishedColor: '#aaaaaa',
  stepIndicatorFinishedColor: '#002f6c',
  stepIndicatorUnFinishedColor: '#ffffff',
  stepIndicatorCurrentColor: '#ffffff',
  stepIndicatorLabelFontSize: 13,
  currentStepIndicatorLabelFontSize: 13,
  stepIndicatorLabelCurrentColor: '#002f6c',
  stepIndicatorLabelFinishedColor: '#ffffff',
  stepIndicatorLabelUnFinishedColor: '#aaaaaa',
  labelColor: '#999999',
  labelSize: 13,
  currentStepLabelColor: '#002f6c',
  cursor: 'pointer'
}