import React, { Component } from "react";
import { StyleSheet, View, TextInput, Text, TouchableOpacity, ActivityIndicator } from "react-native";
import { connect } from "react-redux";
import NewIssue from "../dialogs/newIssue";
import { aiKey, aiApi, aiApiChat } from "../../api";
import Swal from "sweetalert2";
import colors from "../../constants/colors";
export default class ChatAI extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: { response: this.props.initial?.response ? this.props.initial?.response : "", question: ""},
            question: "",
            height: 30,
            openAIResponse: "",
            heightAI: 100,
            loading: false
        };
    }

    checkKeyPressed(val){
        if(val.key == "Enter")this.sendAIMassage();
    }
    sendAIMassage(promptM) {
        const { value, question } = this.state;
        const { id, onChange } = this.props;
        if (question != "") {
            var data;
            var api = aiApi;
            
            data = {
                "model": "text-davinci-003",
                "prompt": question + " in json format",
                "temperature": 0.9,
                "frequency_penalty": 0.0,
                "presence_penalty": 0.6,
                "max_tokens" : 4000 
            }
            
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${aiKey}`
                },
                body: JSON.stringify(data)
            };
            this.setState({ loading: true, openAIResponse: "" })
            fetch(api, options)
                .then(response => response.text())
                .then(data => {
                    const json = JSON.parse(data);
                    const msj = json.choices[0].text;
                    var isObject = true;
                    var object;
                    try {
                        object = JSON.parse(msj);
                    }
                    catch (error) {
                        if (error instanceof SyntaxError) {
                            isObject = false;
                        } else {
                            throw error;
                        }
                    }
                    
                    if (!isObject) {
                        onChange(id, msj.replaceAll("\n", ""), "response");
                        this.setState({ value: { question: value?.question, response: msj.replaceAll("\n", "") } });
                    } else {
                        var formatted = JSON.stringify(object, undefined, 2);
                        onChange(id, formatted, "response");
                        this.setState({ value: { question: value?.question, response: formatted } });
                    }
                    
                    this.setState({ loading: false, editable: true })

                })
                .catch(error => {
                    console.log(error);
                    this.setState({ loading: false })
                    this.showErrorAlert("Sorry, it looks like open AI couldn´t give a response now, please try later")

                });
        }
    }
    sendPromptMessage(promptM) {
        const { value, question } = this.state;
        const { id, onChange } = this.props;
        var messages = promptM.messages;
        if (question != "") {
            var promptMessages = [];
            var promptText = messages.initialContext;
            promptText = promptText + " " + question;
            if (messages.additionalContext) promptText = promptText + " " + messages.additionalContext;
            promptMessages.push({ role: "user", content: promptText })
            var temperature = promptM.temperature ? parseFloat(promptM.temperature) : 0.9;
            var data = {
                "model": "gpt-3.5-turbo-16k",
                "messages": promptMessages,
                "temperature": temperature,
                "frequency_penalty": 0.0,
                "presence_penalty": 0.6
            }
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${aiKey}`
                },
                body: JSON.stringify(data)
            };
            this.setState({ loading: true, openAIResponse: "" })
            fetch(aiApiChat, options)
                .then(response => response.text())
                .then(data => {
                    switch (promptM.sendingType) {
                        case "text":
                            try {
                                const json = JSON.parse(data);
                                const msj = json.choices[0].message.content;
                                var isObject = true;
                                var object;
                                try {
                                    object = JSON.parse(msj);
                                }
                                catch (error) {
                                    if (error instanceof SyntaxError) {
                                        isObject = false;
                                    } else {
                                        throw error;
                                    }
                                }
                                if (!isObject) {
                                    onChange(id, msj.replaceAll("\n", ""), "response");
                                    this.setState({ value: { question: value?.question, response: msj.replaceAll("\n", "") } });
                                } else {
                                    var formatted = JSON.stringify(object, undefined, 2);
                                    onChange(id, formatted, "response");
                                    this.setState({ value: { question: value?.question, response: formatted } });
                                }
                            }
                            catch (error) {
                                console.log(error)
                                this.setState({ loading: false })
                                this.showErrorAlert("Sorry, it looks like open AI couldn´t give a response now, please try later")
                            }
                            break;
                        case "csv":
                            try {
                                const json = JSON.parse(data);
                                const blob = new Blob([json.choices[0].message.content], { type: 'data:text/csv;charset=utf-8,' })
                                const blobURL = window.URL.createObjectURL(blob)
                                const anchor = document.createElement('a')
                                anchor.download = "response.csv"
                                anchor.href = blobURL
                                anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(
                                    ':'
                                )
                                anchor.click()
                                setTimeout(() => {
                                    URL.revokeObjectURL(blobURL)
                                }, 100)
                            } catch (error) {
                                console.log(error);
                                this.setState({ loading: false })
                                this.showErrorAlert("Sorry, it looks like open AI couldn´t form a property structure to create a csv file, please try again");
                            }
                            break;
                        default:
                            try {
                                const json = JSON.parse(data);
                                if (json.error) {
                                    this.showErrorAlert("Sorry, it looks like the request is too large, for now open AI has a maximum context length of 4097 tokens, please select a smaller one and try again.");
                                } else {
                                    this.createForm(JSON.parse(json.choices[0].message.content));
                                }
                            } catch (error) {
                                console.log(error);
                                this.setState({ loading: false })
                                this.showErrorAlert("Sorry, it looks like open AI couldn´t form a property structure to create a new form, please try again");
                            }
                            break;

                    }
                    this.setState({ loading: false, editable: true })
                })
        }
    }
    createForm(form) {
        var valid = this.validateForm(form);
        if (valid) {
            var formatedForm = this.formatForm(form);
            delete formatedForm._id;
            this.props.save(formatedForm, () => {
                this.showErrorAlert("A new form " + form.title + " was created succesfully");
            });
        } else
            this.showErrorAlert("Sorry, it looks like the information given by open AI is incomplete to create a new form, please try again");

    }
    formatForm(form) {
        var newSections = form.sections;
        var newForm = form;
        var index = 1;
        form.sections.map((section, idx) => {
            newSections[idx].id = index.toString();
            index++;
            section.components.map((component, componentIdx) => {
                newSections[idx].components[componentIdx].id = index.toString();
                index++;
            });
        });
        newForm.sections = newSections;

        return newForm;
    }
    validateForm(formatedData) {
        var valid = true;
        if (formatedData.sections) {
            formatedData.sections.map((section) => {
                if (section.components) {
                    section.components.map((component) => {
                        if (!component.label) valid = false;
                    });
                } else {
                    valid = false;
                }
            });
        } else {
            valid = false;
        }

        return valid;
    }
    showErrorAlert(msg) {
        Swal.fire({
            text: msg,
            confirmButtonText: "OK",
            confirmButtonColor: "#F4981E",
            backdrop: false
        });
    }
    render() {
        const { value, openAIResponse, height, heightAI, loading, question } = this.state;
        const { label, onChange, id, isIssuable, onSubmitIssue, isCompleted, placeholder, promptMessages } = this.props;
        console.log(value)
        return (
            <>
                
                <NewIssue
                    visible={this.state.dialogVisible}
                    id={id}
                    label={label}
                    onSubmitIssue={onSubmitIssue}
                    handleClose={() => this.setState({ dialogVisible: false })}
                />

                <View style={styles.container}>
                    <Text style={styles.label}>{this.props.label}</Text>
                    {
                        (isIssuable && isCompleted) &&
                        <TouchableOpacity style={{ paddingBottom: 10, paddingTop: 5 }} onPress={() => {
                            this.setState({ dialogVisible: true });
                        }}>
                            <Text style={{ color: '#002F6C', fontWeight: 'bold' }}>Issue</Text>
                        </TouchableOpacity>
                    }
                    <TextInput
                            value={question}
                            onContentSizeChange={(event) => {
                            console.log(event.nativeEvent.contentSize.height);
                            if (event.nativeEvent.contentSize.height == 0) {
                                if (this.props.placeholder == '') {
                                    this.setState({ height: 30 })
                                }
                                this.setState({ height: event.nativeEvent.contentSize.height });
                            } else {
                                this.setState({ height: event.nativeEvent.contentSize.height });
                            }
                        }}
                        style={styles.input, {
                            borderColor: "#e7e7e7",
                            borderRadius: 5,
                            borderWidth: 1,
                            color: "#424242",
                            fontFamily: "Roboto",
                            fontSize: 20,
                            fontWeight: "400",
                            padding: 5,
                            height: this.state.height,
                            fontStyle: openAIResponse ? openAIResponse == 0 ? 'italic' : 'normal' : 'normal',
                            overflow: 'visible',
                        }}
                        multiline={true}
                        placeholder={placeholder ? placeholder : "Type a question for the AI Assistant"}
                        placeholderTextColor={'rgb(0, 47, 108)'}
                        onChangeText={(val) => {
                            if (val == '' && this.props.placeholder == '') {
                                this.setState({ question: val.replace(/\n/g, ""), height: 30 });

                            } else {
                                this.setState({ question: val.replace(/\n/g, "") });

                            }
                            onChange(id, val, "question");

                        }}
                    />
                    <View style={styles.btnC}>
                        <TouchableOpacity style={styles.btnScan}
                            onPress={() => {
                                    if (promptMessages) {
                                        this.sendPromptMessage(promptMessages);
                                    } else {
                                        this.sendAIMassage();
                                    }
                                
                            }}
                        >
                            <Text style={styles.txtScan}>
                                Send
                            </Text>
                        </TouchableOpacity>
                    </View>
                    <Text style={styles.label}>AI Assistan Response</Text>
                    <View style={styles.aiResponse}>
                        {
                            loading && 
                            <View
                              style={{
                                flex: 1,
                                backgroundColor: "#FFF",
                                padding: 40,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <ActivityIndicator size="large" color={colors.primary} />
                            </View>
                        }
                       <Text style={styles.aiResponseTxt}>
                        {!loading ? value?.response ? value.response : "" : null}
                       </Text> 
                    </View>
                </View>
            </>

        );
    }
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "column",
        paddingBottom: 10,
        paddingHorizontal: 10,
        paddingTop: 5,
        flex: 1,
    },
    input: {
        borderColor: "#e7e7e7",
        borderRadius: 5,
        borderWidth: 1,
        color: "#424242",
        fontFamily: "Roboto",
        fontSize: 22,
        fontWeight: "400",
        paddingLeft: 10,
    },
    label: {
        fontSize: 20,
        color: '#939598',
        fontWeight: '300',
        fontFamily: 'Roboto',

    },
    aiResponse:{
        border: 'solid',
        borderWidth: 1,
        borderColor: 'rgb(231, 231, 231)',
        borderRadius: 4,
        padding: 5,
    },
    aiResponseTxt:{
        minHeight: 30,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 20,
        overflow: "scroll"
    },
    btnC: {
        flex: 1,
        marginBottom: 10
    },
    btnScan: {
        textAlign: "center",
        justifyContent: "center",
        backgroundColor: "#002f6c",
        height: "100%",
        borderRadius: 5,
        marginTop: 10,
        cursor: 'pointer'
    },
    txtScan: {
        textAlign: "center",
        justifyContent: "center",
        color: "white"
    },
});
